import React, { Component } from "react"
import Slider from "react-slick"
import "./HotelSlider.scss"

export default class HotelSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    return (
      <div className="owl-carousel portfilio-slide owl-theme owl-loaded owl-drag hide-desktop">
        <div className="owl-stage-outer">
          <div className="owl-stage">
            <Slider {...settings}>
              <div className="owl-item">
                <div className="item">
                  <div className="box-slide">
                    <img src="https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2018/10/logo-central.png" />
                    <h2>Ovolo Central</h2>
                    <h3>Ownership</h3>
                    <div className="list">
                      <div className="item-list">
                        <h4>Location</h4>
                        <p>Central, HK</p>
                      </div>
                      <div className="item-list">
                        <h4>#Rooms</h4>
                        <p>42</p>
                      </div>
                      <div className="item-list">
                        <h4>Open date</h4>
                        <p>Sep, 2002</p>
                      </div>
                    </div>
                    <div className="visit">
                      <a
                        href="https://ovolohotels.com.hk/ovolocentral/"
                        target="_blank"
                      >
                        Visit Website
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="owl-item">
                <div className="item">
                  <div className="box-slide">
                    <img src="https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2018/10/logo-laneways.png" />
                    <h2>Ovolo laneways</h2>
                    <h3>Ownership</h3>
                    <div className="list">
                      <div className="item-list">
                        <h4>Location</h4>
                        <p>Melbourne, AU</p>
                      </div>
                      <div className="item-list">
                        <h4>#Rooms</h4>
                        <p>43</p>
                      </div>
                      <div className="item-list">
                        <h4>Open date</h4>
                        <p>Sep, 2012</p>
                      </div>
                    </div>
                    <div className="visit">
                      <a
                        href="https://ovolohotels.com.au/ovololaneways/"
                        target="_blank"
                      >
                        Visit Website
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="owl-item">
                <div className="item">
                  <div className="box-slide">
                    <img src="https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2018/10/oaks.jpg" />

                    <h2>Oaks on Londsdale</h2>
                    <h3>Leased to 3rd Party</h3>

                    <div className="list">
                      <div className="item-list">
                        <h4>Location</h4>
                        <p>Melbourne, AU</p>
                      </div>
                      <div className="item-list">
                        <h4>#Rooms</h4>
                        <p>148</p>
                      </div>
                      <div className="item-list">
                        <h4>Open date</h4>
                        <p>2014</p>
                      </div>
                    </div>

                    <div className="visit">
                      <a
                        href="https://oakshotels.com/en/oaks-on-lonsdale"
                        target="_blank"
                      >
                        Visit Website
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="owl-item">
                <div className="item">
                  <div className="box-slide">
                    <img src="https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2018/10/logo-mojo-aberdeen-harbour.png" />

                    <h2>Ovolo Southside</h2>
                    <h3>Ownership</h3>

                    <div className="list">
                      <div className="item-list">
                        <h4>Location</h4>
                        <p>
                          Wong Chuk <br />
                          Hang, HK
                        </p>
                      </div>
                      <div className="item-list">
                        <h4>#Rooms</h4>
                        <p>162</p>
                      </div>
                      <div className="item-list">
                        <h4>Open date</h4>
                        <p>Jun, 2014</p>
                      </div>
                    </div>

                    <div className="visit">
                      <a
                        href="https://ovolohotels.com.hk/ovolosouthside/"
                        target="_blank"
                      >
                        Visit website
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="owl-item">
                <div className="item">
                  <div className="box-slide">
                    <img src="https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2018/10/logo-1888.png" />

                    <h2>Ovolo 1888</h2>
                    <h3>Ownership</h3>

                    <div className="list">
                      <div className="item-list">
                        <h4>Location</h4>
                        <p>Sydney, AU</p>
                      </div>
                      <div className="item-list">
                        <h4>#Rooms</h4>
                        <p>90</p>
                      </div>
                      <div className="item-list">
                        <h4>Open date</h4>
                        <p>Apr, 2015</p>
                      </div>
                    </div>

                    <div className="visit">
                      <a
                        href="https://ovolohotels.com.au/ovolo1888darlingharbour/"
                        target="_blank"
                      >
                        Visit website
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="owl-item">
                <div className="item">
                  <div className="box-slide">
                    <img src="https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2018/10/logo-woolloomooloo.png" />

                    <h2>Ovolo Woollomooloo</h2>
                    <h3>Ownership</h3>

                    <div className="list">
                      <div className="item-list">
                        <h4>Location</h4>
                        <p>Sydney, AU</p>
                      </div>
                      <div className="item-list">
                        <h4>#Rooms</h4>
                        <p>100</p>
                      </div>
                      <div className="item-list">
                        <h4>Open date</h4>
                        <p>Mar, 2016</p>
                      </div>
                    </div>

                    <div className="visit">
                      <a
                        href="https://ovolohotels.com.au/ovolowoolloomooloo/"
                        target="_blank"
                      >
                        Visit website
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="owl-item">
                <div className="item">
                  <div className="box-slide">
                    <img src="https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2018/10/logo-inchcolm.png" />

                    <h2>Ovolo Inchcolm</h2>
                    <h3>Ownership</h3>

                    <div className="list">
                      <div className="item-list">
                        <h4>Location</h4>
                        <p>Brisbane, AU</p>
                      </div>
                      <div className="item-list">
                        <h4>#Rooms</h4>
                        <p>50</p>
                      </div>
                      <div className="item-list">
                        <h4>Open date</h4>
                        <p>2017</p>
                      </div>
                    </div>

                    <div className="visit">
                      <a
                        href="https://ovolohotels.com.au/ovoloinchcolm/"
                        target="_blank"
                      >
                        Visit website
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-item">
                <div className="item">
                  <div className="box-slide">
                    <img src="https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2018/10/logo-mojo-aberdeen-harbour.png" />

                    <h2>Mojo Nomad Aberdeen Hanbour</h2>
                    <h3>Ownership</h3>

                    <div className="list">
                      <div className="item-list">
                        <h4>Location</h4>
                        <p>Aberdeen, HK</p>
                      </div>
                      <div className="item-list">
                        <h4>#Rooms</h4>
                        <p>65</p>
                      </div>
                      <div className="item-list">
                        <h4>Open date</h4>
                        <p>2017 (converted from Ovolo AH)</p>
                      </div>
                    </div>

                    <div className="visit">
                      <a href="http://mojonomad.com/aberdeen" target="_blank">
                        Visit website
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-item">
                <div className="item">
                  <div className="box-slide">
                    <img src="https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2018/10/logo-mojo-central.png" />

                    <h2>The Sheung Wan</h2>
                    <h3>Ownership</h3>

                    <div className="list">
                      <div className="item-list">
                        <h4>Location</h4>
                        <p>Central, HK</p>
                      </div>
                      <div className="item-list">
                        <h4>#Rooms</h4>
                        <p>56</p>
                      </div>
                      <div className="item-list">
                        <h4>Open date</h4>
                        <p>Acquired in 2004, converted in 2018</p>
                      </div>
                    </div>

                    <div className="visit">
                      <a href="http://mojonomad.com/central" target="_blank">
                        Visit website
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-item">
                <div className="item">
                  <div className="box-slide">
                    <img src="https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2018/10/logo-nishi.png" />

                    <h2>Ovolo Nishi</h2>
                    <h3>Lease</h3>

                    <div className="list">
                      <div className="item-list">
                        <h4>Location</h4>
                        <p>Canberra, AU</p>
                      </div>
                      <div className="item-list">
                        <h4>#Rooms</h4>
                        <p>68 rooms / 41 apartments +17 lofts</p>
                      </div>
                      <div className="item-list">
                        <h4>Open date</h4>
                        <p>Jun, 2018</p>
                      </div>
                    </div>

                    <div className="visit">
                      <a
                        href="https://ovolohotels.com.au/ovolonishi/"
                        target="_blank"
                      >
                        Visit website
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-item">
                <div className="item">
                  <div className="box-slide">
                    <img src="https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2018/10/logo-the-valley.png" />

                    <h2>Ovolo The Valley</h2>
                    <h3>Ownership</h3>

                    <div className="list">
                      <div className="item-list">
                        <h4>Location</h4>
                        <p>Brisbane, AU</p>
                      </div>
                      <div className="item-list">
                        <h4>#Rooms</h4>
                        <p>103</p>
                      </div>
                      <div className="item-list">
                        <h4>Open date</h4>
                        <p>Nov, 2018</p>
                      </div>
                    </div>

                    <div className="visit">
                      <a
                        href="https://ovolohotels.com.au/ovolothevalley/"
                        target="_blank"
                      >
                        Visit website
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="owl-nav disabled">
          <button type="button" role="presentation" className="owl-prev">
            <span aria-label="Previous">‹</span>
          </button>
          <button type="button" role="presentation" className="owl-next">
            <span aria-label="Next">›</span>
          </button>
        </div>
      </div>
    )
  }
}
