import React, { FunctionComponent } from "react"
import { ITabItem, IApplyNowItem } from "../types"
import { Tabs as ReactTabs, TabList, Tab, TabPanel } from "react-tabs"
import "./Tabs.scss"
import IconPdf from "../icons/icon-file-pdf-regular.svg"

//Tab.ReacttabsRole = 'Tab';

const ApplyNow: FunctionComponent<{ item: IApplyNowItem }> = ({ item }) => {
  return (
    <div className="post reverse center padded applynow">
      <h3>How To Apply</h3>
      <p dangerouslySetInnerHTML={{ __html: item.application_text }} />{" "}
      <p>
        <a href={item.learn_more.link} className="btn small light">
          <IconPdf /> {item.learn_more_label}
        </a>
      </p>
      <p>
        <a href={"mailto:" + item.apply_now} className="btn small green">
          Apply now
        </a>
      </p>
    </div>
  )
}

export const Tabs: FunctionComponent<{
  items: ITabItem[]
}> = ({ items }) => (
  <ReactTabs>
    <TabList>
      {items.map((item, index) => (
        <Tab key={index}>{item.title}</Tab>
      ))}
    </TabList>
    {items.map((item, index) => (
      <TabPanel key={index}>
        <div dangerouslySetInnerHTML={{ __html: item.content }} />{" "}
        {item.application_text && <ApplyNow item={item} />}
      </TabPanel>
    ))}
  </ReactTabs>
)
