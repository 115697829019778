import React from "react"
import PropType from "prop-types"
import Helmet from "react-helmet"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import Booking from "../components/Booking.tsx"
import BookingWidget from "../components/BookingWidget.tsx"
import Title from "../components/Title.tsx"
import Layout from "../components/layout"
import { isMobile } from "react-device-detect"
import Sticky from "../components/Sticky.tsx"
import Content from "../components/Content.tsx"
import Subnavigation from "../components/Subnavigation.tsx"
import Grid from "react-css-grid"
import classNames from "classnames"
import { CssGrid } from "../components/CssGrid"
import SEO from "../components/Seo.js"
import ImageCarousel from "../components/ImageCarousel.tsx"
import TestimonialCarousel from "../components/TestimonialCarousel.tsx"
import HotelSlider from "../components/HotelSlider.tsx"

import { Tabs } from "../components/Tabs.tsx"

import { BOOKING_CONFIG } from "../constants"

let pageid
let PROPRIETIES_PAGE_ITEMS
let PROPRIETIES_CAROUSEL_ITEMS = {}
let TESTIMONIALS = {}
let INTERNSHIP_PROGRAMS = {}
let MANAGEMENT_PROGRAMS = {}
let SUBMENU = []

// const stripHtml = (html) => {
//   if (typeof window !== 'undefined') {
//     const doc = new DOMParser().parseFromString(html, 'text/html');
//     return doc.body.textContent || '';
//   }
//   return html;
// };

const PostTemplate = props => {
  const {
    data: { wordpressPage: post, submenu, acfOptions },
  } = props

  acfOptions.edges.map(
    ({ node }) => (PROPRIETIES_CAROUSEL_ITEMS = node.options.proprieties)
  )
  acfOptions.edges.map(
    ({ node }) => (TESTIMONIALS = node.options.internship_testimonials)
  )

  acfOptions.edges.map(
    ({ node }) => (INTERNSHIP_PROGRAMS = node.options.internship_programs)
  )
  acfOptions.edges.map(
    ({ node }) => (MANAGEMENT_PROGRAMS = node.options.management_programs)
  )

  if (post.wordpress_id === 8) {
    PROPRIETIES_PAGE_ITEMS = PROPRIETIES_CAROUSEL_ITEMS.filter(item => {
      return item.brand === "ovolo"
    })
  } else if (post.wordpress_id === 9) {
    PROPRIETIES_PAGE_ITEMS = PROPRIETIES_CAROUSEL_ITEMS.filter(item => {
      return item.brand === "mojo"
    })
  } else {
    PROPRIETIES_PAGE_ITEMS = PROPRIETIES_CAROUSEL_ITEMS
  }

  pageid = "internal-page page-" + post.wordpress_id

  return (
    <Layout>
      <Helmet link={[{ rel: "canonical", content: post.yoast.canonical }]} />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <SEO
        title={post.yoast.title || post.acf.title_seo || post.title}
        description={
          post.yoast.metadesc || post.acf.paragraph_seo || post.excerpt
        }
        pathname={post.slug}
        article
      />
      {post.acf.two_collums == "yes" && (
        <Sticky
          backgroundImage="https://wp.ovologroup.com/wp-content/uploads/2019/04/ovolo.jpg"
          notop
          component={
            <BookingWidget
              hotelsConfig={BOOKING_CONFIG}
              title="Book a Room"
              arriveLabel="Arrive"
              departLabel="Depart"
              guestLabel="Guest"
              hideMobile
              compact
            />
          }
        >
          {submenu.edges.map(({ node }) => {
            SUBMENU = node.items
          })}

          <Subnavigation items={SUBMENU} />
          <Title
            title={post.acf.title_seo ? post.acf.title_seo : post.title}
            subtitle={post.acf.subtitle_seo}
            paragraphs={post.acf.paragraph_seo}
          />
          <div className="post">
            {" "}
            {post.wordpress_id === 353 && (
              <TestimonialCarousel items={TESTIMONIALS} />
            )}
            <div dangerouslySetInnerHTML={{ __html: post.content }} />{" "}
            {post.wordpress_id === 353 && <Tabs items={INTERNSHIP_PROGRAMS} />}
            {post.wordpress_id === 351 && <Tabs items={MANAGEMENT_PROGRAMS} />}
          </div>
        </Sticky>
      )}
      {post.acf.two_collums === "no" && (
        <CssGrid className={pageid}>
          <article>
            <div className="sticky">
              <div dangerouslySetInnerHTML={{ __html: post.content }} />
              {post.wordpress_id !== 289 && (
                <div className={"carousel-padding"}>
                  <Content background="light" pcarousel>
                    <ImageCarousel
                      slidesPerPage={6}
                      slidesPerScroll={1}
                      items={PROPRIETIES_PAGE_ITEMS}
                      offset={20}
                    />
                  </Content>
                </div>
              )}
              {post.wordpress_id === 289 && (
                <>
                  <HotelSlider />
                  <div
                    class="content title-block_5ce42aff18083  details-title grey light"
                    id="contact"
                  >
                    <div class="column">
                      <h2>Contact Details</h2>
                    </div>
                  </div>
                  <div class="wp-block-columns has-2-columns sc-bdVaJa kHPcJw details grey">
                    <div class="wp-block-column">
                      <div class="content title-block_5ce3170029fe5 light ">
                        <div class="column">
                          <h2>Kyu Baek Kim</h2>
                          <p>Acquisition &amp; Development Manager</p>
                        </div>
                      </div>
                    </div>
                    <div class="wp-block-column">
                      <div class="content title-block_5ce42afa18082   light ">
                        <div class="column">
                          <h2>Ovolo Hotels</h2>
                          <p />
                          <p>
                            <a href="tel:+852 5663 4717">
                              <img
                                src="https://ovologroup.wpengine.com/wp-content/uploads/2019/05/icon-mail.png"
                                alt=""
                                width="29"
                                height="19"
                                class="alignnone size-full wp-image-312"
                              />
                            </a>
                            <a href="mailto:kyubaek.kim@ovologroup.com">
                              kyubaek.kim@ovologroup.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </article>
        </CssGrid>
      )}
    </Layout>
  )
}
PostTemplate.propTypes = {
  data: PropType.shape({}).isRequired,
}
export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    submenu: allWordpressWpApiMenusMenusItems(
      filter: { slug: { eq: "about" } }
    ) {
      edges {
        node {
          items {
            title
            url
            target
          }
        }
      }
    }
    acfOptions: allWordpressAcfOptions {
      edges {
        node {
          options {
            proprieties {
              title
              link
              brand
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 237, maxHeight: 237) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            internship_testimonials {
              text
              image {
                source_url
              }
            }
            internship_programs {
              title
              content
              application_text
              apply_now
              learn_more_label
              learn_more {
                link
              }
            }
            management_programs {
              title
              content
              application_text
              apply_now
              learn_more_label
              learn_more {
                link
              }
            }
          }
        }
      }
    }
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      slug
      yoast {
        title
        metadesc
        canonical
      }
      content
      date(formatString: "DD, MMM YYYY")
      featured_media {
        source_url
        localFile {
          childImageSharp {
            id
            sizes(maxWidth: 1000) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
      acf {
        title_seo
        subtitle_seo
        paragraph_seo
        two_collums
      }
      author {
        name
        description
      }
    }
  }
`
