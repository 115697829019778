import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
import classNames from "classnames"
import React, { FunctionComponent } from "react"
import { isMobileOnly, isTablet } from "react-device-detect"
import { ITestimonialCarouselItem } from "../types"
import "./TestimonialCarousel.scss"

export const TestimonialCarousel: FunctionComponent<{
  items: ITestimonialCarouselItem[]
}> = ({ items }) => {
  return !isMobileOnly ? (
    <div
      className={classNames("testimonial-carousel", {
        mobile: isMobileOnly,
        tablet: isTablet,
      })}
    >
      <Carousel slidesPerPage={1} slidesPerScroll={1} infinite arrows centered>
        {items.map((item, index) => (
          <div className="item" key={index}>
            <div className="testimonial">
              <p dangerouslySetInnerHTML={{ __html: item.text }} />{" "}
            </div>
            <img src={item.image.source_url} alt={item.title} />
          </div>
        ))}
      </Carousel>
    </div>
  ) : (
    <></>
  )
}

export default TestimonialCarousel
